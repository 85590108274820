import { post } from '@/utils/request'

//获取目录
export function getList(data) {
    return post('psi/warehouse/listWare', data)
}
//修改
export function getEdit(data) {
    return post('psi/warehouse/editWare', data)
}
//添加
export function getAdd(data) {
    return post('psi/warehouse/addWare', data)
}
//详情
export function getInfo(data) {
    return post('psi/warehouse/infoWare', data)
}
//删除
export function getDel(data) {
    return post('psi/warehouse/delWare', data)
}






